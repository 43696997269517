.icon {
  display: inline-block;
  vertical-align: text-bottom;
  color: var(--color-icon);
}

.icon.dark {
  color: var(--color-icon-strong);
}

.icon svg:not(:global(.two-tone)) {
  fill: currentColor;
}

.icon svg:global(.two-tone) :global(.fill) {
  fill: currentColor;
  opacity: 0.3;
}

.icon svg:global(.two-tone) :global(.stroke) {
  stroke: currentColor;
}

.emoji {
  font-size: inherit;
  line-height: inherit;
  color: black !important;
}

/*
* Sizes
*/

.largeSize {
  font-size: 30px;
  width: 30px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
}

.mediumSize {
  font-size: 24px;
  width: 24px;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
}

.xlargeSize {
  font-size: 44px;
  width: 44px;
  min-width: 44px;
  height: 44px;
  line-height: 44px;
}

.xxlargeSize {
  font-size: 80px;
  width: 80px;
  min-width: 80px;
  height: 80px;
  line-height: 80px;
}

.defaultSize {
  font-size: 16px;
  width: 20px;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
}

.smallSize {
  font-size: 15px;
  width: 18px;
  min-width: 18px;
  height: 18px;
  line-height: 18px;
}

.xsmallSize {
  font-size: 14px;
  width: 16px;
  min-width: 16px;
  height: 16px;
  line-height: 16px;
}

/*
* Counter Icon
*/
.counter {
  padding: 1px;
}

.count {
  display: block;
  background: var(--color-background-secondary);
  font: var(--font-text-bold);
  color: var(--color-text-inverse);
  font-size: 10px;
  line-height: 18px;
  border-radius: 100px;
  text-align: center;
  letter-spacing: 0.5px;
  width: 18px;
  height: 18px;
}

.count.subtle {
  background: var(--color-background-secondary);
  color: var(--color-text-secondary);
}


/*
* Color Indicator
*/

.swatch {
  display: inline-block;
  text-align: center;
  width: calc(100%);
  height: calc(100%);
  border-radius: 2px;
}

.colorIndicator {
  padding: 1px;
  width: 10px;
  height: 10px;
}

.unreadIndicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 8px;
}

.color {
  display: block;
  background: var(--color-icon);
  color: var(--color-text-inverse);
  border-radius: 100px;
  width: 100%;
  height: 100%;
}


.color.red {
  background: var(--color-user-red-2);
}

.color.blue {
  background: var(--color-user-blue-2);
}

.color.purple {
  background: var(--color-user-purple-2);
}

.color.brown {
  background: var(--color-user-brown-2);
}

.color.pink {
  background: var(--color-user-pink-2);
}

.color.yellow {
  background: var(--color-user-yellow-2);
}

.color.gray {
  background: var(--color-user-gray-2);
}

.color.orange {
  background: var(--color-user-orange-2);
}

.color.green {
  background: var(--color-user-green-2);
}

/*
* Other
*/

.spinning {
  animation-name: spin;
  animation-duration: 1300ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*
* Check icon
*/

.checkIcon {
  border-radius: 4px;

  outline: 2px solid var(--color-border);
  outline-offset: -2px;
  transition: background 200ms ease;
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
}

.check {
  color: white;
}

.checkIcon:hover {
  background: var(--color-subtle-hover);
}

.checked {
  outline: none;
}

/*
* Slack Person Icon
*/
.slackPerson {
  border-radius: 4;
}

/* 
* Image Icon
*/

.image {
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
}

/*
* Radio Icon
*/

.radioIcon {
  width: 20px;
  height: 20px;
  border: 2px solid var(--color-border);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radioIcon.checked {
  background: var(--color-primary);
  border-color: var(--color-invisible);
}

.radioDot {
  all: unset;
  width: 8px;
  height: 8px;
  background: var(--color-background);
  border-radius: 50%;
  display: block;
}

/*
* Team Icon
*/
.fill.fill {
  border-radius: inherit;
  height: 100%;
  width: 100%;
}