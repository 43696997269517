.modal {
  width: 700px;
}

.title, .title:hover, .title:active, .title:focus, .title.title {
  background: none;
  border: none;
  font: var(--font-text-x-large);
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -6px;
  margin-right: -6px;
  width: calc(100% + 12px);
}

/* Prevent dialog jumping on first run */
.docWrapper {
  min-height: 54px;
  width: 100%;
}

.hiddenLocation {
  opacity: 0;
  width: 0;
  margin-right: -10px;
  pointer-events: none;
}

.schedulePopover {
  min-width: 400px;
  padding: 6px;
}

.floatingBelow {
  position: absolute;
  bottom: -300px;
  left: 0;
  width: 100%;
}