.section {
  position: relative;
  border-radius: var(--border-radius-medium);
}

.secondary {
  background-color: var(--color-background-tertiary);
  padding: 12px;
}

.background {
  background-color: var(--color-background);
}

.noWrap {
  text-wrap: nowrap;
}

.header {
  padding: 0;
}

.header.padded {
  padding: 0 6px;
}

.inset {
  margin-left: -6px;
  margin-right: -6px;
  width: calc(100% + 12px);
}