.container {
  height: 100%;
}

.main {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.optionsPane.optionsPane {
  max-width: 500px;
}

.triagePadding {
  padding-bottom: 80px;
}

.triagePadding > * {
  padding-bottom: 20px;
}

.noBorder {
  border: none;
}

.noTopBorder {
  border-top: none;
}
