.pill {
  border: var(--border-secondary);
  border-radius: 100px;
  display: inline-block;
}

.pill.default {
  padding: 6px;
}

.pill.small {
  padding: 3px 6px 3px 3px;
}

.pill.tiny {
  padding: 0;
}

/* Hack because PropertyValue currently adds classname to container + button */
/* Didn't want to change as not sure consequenses */
.pill .pill {
  border: none;
  padding: 0;
}