/* EngineStyles */ 
.menuProgressBar {
  min-width: 50px;
}

/* Style inclusions making this weaker than it should... */
.flowPreview.flowPreview {
  width: 100%;
  height: 500px;
}

/* Pane */
.timeline {
  /* position: absolute; */
  height: 30px;
  margin-left: 20px;
  top: 20px;
  bottom: 20px;
  border-left: 1px dashed var(--color-border);
}

/* MenuList */
.detailPopover {
  min-width: 300px;
}

.detailPopover > * {
  padding: 6px 4px;
}

.detailPopover .menu {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: visible;
}