.thread {
  min-height: 100%;
}

.title {
  color: var(--color-text);
}

.update {
  padding: 20px;
  background: var(--color-background);
  border-bottom: var(--border-secondary);
}

.clip {
  text-overflow: ellipsis;
}

.header.header {
  background-color: var(--color-background);
}

.text {
  font: var(--font-text-medium);
  width: 100%;
}