.container {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.container.appLayout {
  display: grid;
  grid-template-areas:
    "sidebar main"
    "bottom bottom";
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr 25px;
  max-height: 100vh;
  overflow: hidden;
  /* New z-index stacking context */
  isolation: isolate;
}

.container.centered {
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40vh;
}

/* TODO: Move to global vars */
@media (max-width: 800px) {
  .container.centered {
    padding-bottom: 20px;
  }
}

.container.sideStack {
  flex-direction: row;
}

.container.topStack {
  flex-direction: column;
}

.container.root {
  height: 100vh;
  width: 100vw;
}

.main {
  grid-area: main;
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
  overscroll-behavior: contain;
  height: 100%;
  z-index: 0;
}

.sideStack .main {
  height: 100%;
}

.topStack .main {
  width: 100%;
}

.sidebar {
  grid-area: sidebar;
  height: 100%;
  flex: 0 0 auto;
}

.topbar {
  width: 100%;
  height: auto;
  flex: 0 0 auto;
  overflow: hidden auto;
}

.primaryNav {
  height: 100%;
  flex: 0 0 auto;
  z-index: 1;
}

.primaryNav:empty {
  display: none;
}

.footerBar {
  grid-area: bottom;
  width: 100%;
  flex: 0 0 auto;
  align-content: center;
  padding: 0 20px;
  overflow: hidden auto;
  border-top: 1px solid var(--color-border-secondary);
}
