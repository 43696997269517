/*
* Pane
*/ 


.agenda {
  padding: 8px 8px;
  margin: -8px -8px;
  width: calc(100% + 16px);
  position: relative;
}

.agendaDivider {
  opacity: 0;
}

.onHover {
  opacity: 0;
}

.agenda.focus-within .onHover,
.agenda:hover .onHover {
  opacity: 1;
}

.agenda.active::before {
  content: '';
  position: absolute;
  top: 28px;
  right: 100%;
  width: 20px;
  height: 1px;
  background: var(--color-user-red);
}

.nowIndicator {
  content: '';
  position: absolute;
  top: 28px;
  left: -2px;
  width: 6px;
  height: 1px;
  background: var(--color-user-red);
}

.header {
  padding: 4px;
  border-radius: var(--border-radius-small);
  color: var(--color-text-heading);
}

.agenda .header:hover {
  background-color: var(--color-subtle-hover);
}

.agendaTitle {
  font: var(--font-text-medium-bold);
  /* Override placeholder to always look like a real value */
  color: var(--color-text-heading) !important;
}

.agendaTitle :global(.ProseMirror:not(.ProseMirror-focused)) *[data-placeholder]::before {
  color: var(--color-text-heading);
}

.agendaActions {
  padding-bottom: 16px;
}

.agendaSection {
  padding-left: 0;
  padding-right: 0;
}

.strikethrough {
  text-decoration: line-through;
}

.body {
  padding-top: 8px;
  padding-left: 16px;
}


.meetingDivider {
  width: 4px;
  min-width: 4px;
  border-radius: 3px;
  display: block;
  height: auto;
}

.control {
  background: var(--color-background-tertiary);
}

.relative,
.timeInput {
  position: relative;
}


/*
* Create Modal
*/

.createModal {
}

.splitLeft {
  max-width: 250px;
}