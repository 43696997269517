.hideEmpty:empty {
  display: none !important;
}

.noBottomPad {
  padding-bottom: 0;
}

.noPadTop {
  padding-top: 0;
}

.editor {
  padding-top: 20px;
}

.subtext {
  color: var(--color-text-secondary);
  font: var(--font-text-small);
}

.control {
  background-color: var(--color-background-tertiary);
  width: 100%;
}
