
.value {
  width: 100%;
  background-color: var(--color-background-tertiary);
}

.control {
  border-radius: var(--border-radius-small);
  background-color: var(--color-background-tertiary);
}
