.noHighlight {
  pointer-events: none;
}

.noBackground:hover,
.noBackground:active,
.noBackground {
  background: none;
  border: none;
  padding: 6px;
  box-shadow: none;
  margin: 0;
}

.inline > *,
.inline > * > *,
.inline > * > * > * {
  display: inline;
  white-space: nowrap !important;
  line-height: 1em;
  margin-right: 3px;
}

.inline hr,
.inline br {
  display: none !important;
}
