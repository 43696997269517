.dialog {
  max-width: min(800px, calc(100vw - 20px));
  width: 100vw;
  height: 600px;
}

.dialog.passive {
  max-width: min(500px, calc(100vw - 20px));
}

.container {
  display: flex;
  flex-direction: row;
  column-gap: 0;
  row-gap: 0;
  justify-content: flex-start;
  align-items: flex-start;
}

.content {
  overflow: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100%;
  width: 100%;
}

.fullWidth {
  width: 100%;
}

.content {
  height: 100%;
  overflow-x: visible;
  padding: 20px;
  padding-bottom: 0;
}

.actions {
  padding: 20px;
  padding-top: 0;
}

.dialog.split {
  background: transparent;
  backdrop-filter: blur(10px);
}

.splitBottom,
.splitLeft {
  /* Mid transparency */
  background: rgba(var(--color-rgb-background), 0.2);
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  height: 600px;
  flex-basis: 35%;
  overflow-y: auto;
}

.splitBottom {
  overflow-x: auto;
}

.splitTop {
  /* Low transparency */
  overflow-y: auto;
  border-bottom: var(--border-secondary);
  background: rgba(var(--color-rgb-background), 0.85);
  padding: 0;
}

.splitRight {
  /* Low transparency */
  overflow-y: auto;
  border-left: 1px solid color-mix(in srgb, var(--color-border) 40%, var(--color-background));
  background: rgba(var(--color-rgb-background), 0.85);
  padding: 0;
  height: 600px;
}

.splitRight:first-child {
  width: 100%;
  flex-basis: 100%;
}

/* TODO: Move to global vars */
@media (max-width: 800px) {
  .splitLeft {
    display: none !important;
  }

  .splitRight {
    flex-basis: 100%;
  }
}

/* Vertical layout puts side component underneath */
.dialog.vertical {
  height: auto;
}

.dialog.vertical .splitBottom {
  padding: 10px 20px;
}

.dialog.vertical .splitBottom,
.dialog.vertical .splitTop {
  flex-basis: 100%;
  height: auto;
}

.dialog.vertical .content {
  padding: 20px;
}

.dialog.vertical .container {
  flex-direction: column;
}

