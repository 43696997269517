.container {
  display: grid;
  min-height: 100%;
  grid-template-areas:
    "main sidebar";
  grid-template-columns: 1fr calc(max(min(40%, 800px), 400px));
  grid-template-rows: 1fr;
  overflow: visible;
}

.header {
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 1;
  padding: 10px 20px;
  background: var(--color-background);
  border-radius: var(--border-radius-medium);
  box-shadow: var(--shadow-elevation-low);
  border: var(--border-tertiary);
}

.pane {
  grid-area: sidebar;
  background-color: var(--color-background);
  border-left: var(--border-secondary);
  box-shadow: var(--shadow-elevation-mid);
  padding: 16px;
  overflow: auto;
}

.control {
  background: var(--color-background-tertiary);
  width: 100%;
  border-radius: var(--border-radius-small);
  padding: none;
}

.addModal {
  width: 400px;
}

.squareButton {
  flex-basis: calc(100%/3);
}

.addButton {
  padding-top: 40px;
  padding-bottom: 40px;
}

.addButton .stack>* {
  z-index: 2;
}

.addButton .stack>*:nth-child(1) {
  transform: translateX(35px) rotate(-25deg);
  z-index: 0;
}

.addButton .stack > *:nth-child(2) {
  transform: translateX(15px) rotate(-15deg);
  z-index: 0;
}

.addButton .stack>*:nth-last-child(2) {
  transform: translateX(-15px) rotate(15deg);
  z-index: 1;
}

.addButton .stack>*:last-child {
  transform: translateX(-35px) rotate(25deg);
  z-index: 0;
}

.main {
  position: relative;
  grid-area: main;
}

.floatingInput {
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 300px;
  transform: translateX(-50%);
  padding: 10px 8px;
  border-radius: 200px;
  background-color: var(--color-background);
  box-shadow: var(--shadow-elevation-mid);
  transition: transform 0.3s var(--snappy-bezier), width 0.3s var(--snappy-bezier), box-shadow 0.3s ease-in-out;
  cursor: text;
  border: 1px solid transparent;
}

.floatingInput:hover {
  border: 1px solid var(--color-border-secondary);
}

.floatingInput:hover .input,
.floatingInput .input {
  border: none;
  background: none;
  outline: none;
  height: 100%;
}

.floatingInput.large {
  width: 400px;
  transform: translateX(-50%) translateY(-20vh);
  box-shadow: var(--shadow-elevation-high);
  border: 1px solid var(--color-border-secondary);
}


/* Hack to make tabbar fill, should add as an option to TabBar */
.tabBar {
  width: 100%;
  position: sticky;
  top: 0;
}

.tabBar button {
  flex-grow: 1;
}

.tabBar button > div {
  width: 100%;
  text-align: center;
}

.center {
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
}

/* Steps Editors */

.numberInput {
  max-width: 120px;
}

.filterMenu {
  padding: 2px;
}

.actionsModal {
  width: 400px;
}



.hoverLink {
  opacity: 0.7;
}
.hoverLink:hover {
  opacity: 1;
}

.variableRow > *:first-child,
.variableRow > *:last-child {
  width: 50%;
  flex-grow: 1;
}