.card {
  transition: box-shadow 50ms ease-in-out;
  border: var(--border-tertiary);
  border-radius: var(--border-radius-medium);
  box-shadow: var(--shadow-elevation-low);
  background-color: var(--color-background);
  overflow-x: hidden;
  overflow-y: auto;
}

.padded {
  padding: 10px;
}

.card.interactable:hover {
  border-color: var(--color-invisible);
  box-shadow: var(--shadow-elevation-mid);
}

.card:active {
  box-shadow: var(--shadow-elevation-none);
}
