.container {
  width: 100%;
  overflow-x: auto;
  border: 1px solid var(--color-border-tertiary);
  border-radius: 3px;
  background: var(--color-background);
  /* Create a more subtle border color for the grid */
  --table-border-color: color-mix(in srgb, var(--color-border-tertiary) 50%, transparent);
}

.table {
  width: 100%;
  min-width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 14px;
  /* This ensures borders don't collapse and we get proper grid lines */
  background: linear-gradient(var(--color-border-tertiary) 1px, transparent 0);
  background-size: 1px 100%;
}

/* Header styles */
.headerCell {
  position: sticky;
  top: 0;
  background: var(--color-background);
  padding: 0 6px;
  text-align: left;
  font-weight: 500;
  color: var(--text-subtle);
  /* border-right: 1px solid var(--color-border-tertiary); */
  border-bottom: 1px solid var(--color-border-tertiary);
  white-space: nowrap;
  font-size: 12px;
  vertical-align: middle;
  height: 32px;
  /* Ensure header stays above content with grid lines */
  z-index: 1;
}

.headerContent {
  display: flex;
  align-items: center;
  gap: 4px;
}

.headerCell.sortable {
  cursor: pointer;
}

.headerCell.sortable:hover {
  background-color: var(--color-background-secondary);
}

.sortIcon {
  display: inline-flex;
  align-items: center;
  color: var(--text-subtle);
  opacity: 0;
  transition: opacity 0.1s ease;
}

.headerCell:hover .sortIcon {
  opacity: 0.5;
}

.sortIcon.active {
  opacity: 1;
  color: var(--text-default);
}

/* Row styles */
.cell {
  /* padding: 6px 8px; */
  border: 0;
  /* Add right border for column separation */
  /* border-right: 1px solid var(--color-border-tertiary); */
  border-bottom: 1px solid var(--color-border-tertiary);
  height: 32px;
  font-size: 14px;
  vertical-align: middle;
  white-space: nowrap;
  color: var(--text-default);
  background-color: inherit;
}

.row.selected .cell {
  border-bottom-color: transparent;
}

.cellValue {
  width: 100%;
  max-width: 100%;
}

/* First column styles */
.cell:first-child {
  padding-left: 8px;
}

.headerCell:first-child {
  padding-left: 8px;
}

/* Last column styles */
.cell:last-child,
.headerCell:last-child {
  padding-right: 16px;
}

/* Add input styles */
.addInputContainer {
  border-top: 1px solid var(--color-border-tertiary);
  padding: 8px 16px;
  background: var(--color-background-secondary);
}

/* Load more button styles */
.loadMore {
  padding: 8px 16px;
  text-align: center;
  border-top: 1px solid var(--color-border-tertiary);
}

.loadMoreButton {
  color: var(--text-subtle);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 13px;
  padding: 4px 8px;
  border-radius: 3px;
}

.loadMoreButton:hover {
  background: var(--color-background-secondary);
  color: var(--text-default);
}

/* Empty state styles */
.emptyStateContainer {
  padding: 24px;
  text-align: center;
  background: var(--color-background);
}

/* Cell specific styles */
.cellContent {
  display: flex;
  align-items: center;
  gap: 6px;
  min-height: 20px;
}

/* Property icon styles */
.propertyIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  color: var(--text-subtle);
  flex-shrink: 0;
}

/* Selection styles */
.checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid var(--color-border-tertiary);
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.1s ease;
}

.checkbox:hover {
  border-color: var(--text-subtle);
  background: var(--color-background-secondary);
}

.checkbox.checked {
  background: var(--primary-default);
  border-color: var(--primary-default);
}

/* Resizable column styles */
.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 3px;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  opacity: 0;
  transition: opacity 0.1s ease;
}

.headerCell:hover .resizer {
  opacity: 1;
  background: var(--primary-default);
}