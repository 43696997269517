.inputWrapper {
  position: relative;
  display: block;
  width: 100%;
}

.icon {
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
}

.inputWrapper.indent .input {
  padding-left: 28px;
}

.input {
  color: var(--color-text);
  font: var(--font-text);
  padding: 5px 8px;
  min-width: 50px;
  width: 100%;
}

.input.disabled {
  pointer-events: none;
}

.input::placeholder {
  color: var(--color-text-placeholder);
}

.input.invalid {
  border-color: var(--color-danger);
  background-color: var(--color-subtle-danger);
}

.default {
  background: var(--color-background-tertiary);
  border: var(--border-invisible);
  border-radius: 7px;
}

.default:hover {
  background: var(--color-subtle-hover);
}

.default:active {
  background: var(--color-background);
}

.default:focus {
  background: var(--color-background);
  border-color: var(--color-primary);
}

.secondary {
  background: var(--color-background);
  border: var(--border);
  border-radius: 4px;
}

.secondary:hover {
  background: var(--color-subtle-hover);
}

.secondary:active {
  background: var(--color-background);
}

.secondary:focus {
  background: var(--color-background);
  border-color: var(--color-primary);
}

.field {
  display: block;
}

.field.container {
  overflow: visible;
  width: 100%;
}

.field.horizontal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 4px;
}

.field.horizontal > :last-child {
  max-width: 68%;
}

.field.vertical {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.field.vertical > * {
  display: block;
  flex-grow: 1;
  width: 100%;
}

.field .padding {
  padding-left: 6px;
  padding-right: 6px;
}

.label {
  font: var(--font-text);
  color: var(--color-text-secondary);
  padding-left: 6px;
}

.help {
  font: var(--font-text-small);
  color: var(--color-text-tertiary);
  padding-left: 6px;
}

.field.horizontal > .labelContainer {
  width: 45%;
}

.fieldStack {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  width: 100%;
  background-color: var(--color-background-secondary);
  border-radius: 4px;
}

.fieldStack > * {
  display: block;
  flex-grow: 1;
  width: 100%;
}
